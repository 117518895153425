<template>
  <div>
    <div class="page-bgf6">
      <div class="wrapper-content">
        <div class="partners-title">
          <h2>申请发票</h2>
        </div>
        <div class="partners-content">
          <el-form :model="dataForm" :rules="baseRule" ref="dataForm" label-width="100px" class="demo-ruleForm apply-form" label-position="left">
            <el-form-item label="发票类型：" prop="invoiceType">
              <el-radio-group v-model="dataForm.invoiceType">
                <el-radio :label="1">增值税普通发票</el-radio>
                <el-radio :label="2">增值税专用发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="抬头类型：" prop="headerType">
              <el-radio-group v-model="dataForm.headerType" @change="headerTypeChange">
                <el-radio :label="1">个人</el-radio>
                <el-radio :label="2">企业</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="我的抬头：" prop="invoiceHeader">
              <el-button type="primary" size="mini" @click="invoiceHeaderOpen(dataForm.headerType)">选择抬头</el-button>
            </el-form-item>
            <el-form-item label="发票抬头：" prop="invoiceHeader">
              <el-input v-model="dataForm.invoiceHeader" placeholder="请输入发票抬头" clearable/>
            </el-form-item>
            <template v-if="dataForm.headerType==1">
              <el-form-item label="联系电话：" prop="contactTelePhone">
                <el-input v-model="dataForm.contactTelePhone" maxlength="11" placeholder="请输入联系电话" clearable/>
              </el-form-item>
            </template>
            <template v-if="dataForm.headerType==2">
              <el-form-item label="公司税号：" prop="unitTaxNo">
                <el-input v-model="dataForm.unitTaxNo" placeholder="请输入公司税号" clearable/>
              </el-form-item>
              <el-form-item label="公司地址：" prop="companyAddress">
                <el-input v-model="dataForm.companyAddress" placeholder="请输入公司地址" clearable/>
              </el-form-item>
              <el-form-item label="开户银行：" prop="bankDeposit">
                <el-input v-model="dataForm.bankDeposit" placeholder="请输入开户银行" clearable/>
              </el-form-item>
              <el-form-item label="银行账号：" prop="bankAccount">
                <el-input v-model="dataForm.bankAccount" placeholder="请输入银行账号" clearable/>
              </el-form-item>
              <el-form-item label="公司电话：" prop="companyTelePhone">
                <el-input v-model="dataForm.companyTelePhone" maxlength="11" placeholder="请输入公司电话" clearable/>
              </el-form-item>
            </template>
            <el-form-item label="收件地址：" prop="receiveAddress">
              <el-input v-model="dataForm.receiveAddress" placeholder="请输入收件地址" clearable/>
            </el-form-item>
            <el-form-item label="接收邮箱：" prop="receiveEmail">
              <el-input v-model="dataForm.receiveEmail" placeholder="请输入接收邮箱" clearable/>
            </el-form-item>
            <el-form-item label="发票内容：" prop="invoiceContent">
              <el-input type="textarea" :rows="4" v-model="dataForm.invoiceContent" placeholder="请输入发票内容" clearable/>
            </el-form-item>
          </el-form>
          <div class="partners-btn">
            <div class="btn-bar">
              <el-button @click="resetForm">取消</el-button>
              <el-button type="primary" @click="dataFormSubmit" :loading="btnLoading">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="发票抬头列表" :visible.sync="invoiceHeaderVisible" center class="certificationDialog big-dialog" top="8vh">
      <el-table v-loading="listLoading" :data="dataList">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="抬头信息" >
          <template slot-scope="scope">{{scope.row.invoiceHeader}}{{scope.row.unitTaxNo}}</template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleAddEdit(scope.row)">选择</el-button>
            <el-button type="text" class="JNPF-table-delBtn" @click="handleDel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  newOrderOrderInvoiceAdd,
  newOrderOrderInvoiceApply,
  newOrderOrderInvoiceDel,
  newOrderOrderInvoiceList
} from "../../api/order";

export default {
  name: "invoiceOpen.vue",
  data(){
    return{
      invoiceHeaderVisible:false,
      dataList:[],
      listLoading:false,
      dialogVisible:false,
      btnLoading:false,
      dataForm:{
        "bankAccount": "",
        "bankDeposit": "",
        "companyAddress": "",
        "companyTelePhone": "",
        "contactTelePhone": "",
        "headerType": 1,
        "invoiceContent": "",
        "invoiceHeader": "",
        "invoiceId": "",
        "invoiceType": 1,
        "orderNo": this.$route.params.id,
        "receiveAddress": "",
        "receiveEmail": "",
        "unitTaxNo": ""
      },
      baseRule:{
        name: [
          // { required: true, message: '请输入供应商名称', trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    this.dataForm = {
      "bankAccount": "",
      "bankDeposit": "",
      "companyAddress": "",
      "companyTelePhone": "",
      "contactTelePhone": "",
      "headerType": 1,
      "invoiceContent": "",
      "invoiceHeader": "",
      "invoiceId": "",
      "invoiceType": 1,
      "orderNo": this.$route.params.id,
      "receiveAddress": "",
      "receiveEmail": "",
      "unitTaxNo": ""
    }
  },
  methods:{
    //选择发票抬头
    invoiceHeaderOpen(headerType){
      this.invoiceHeaderVisible = true
      this.getOrderOrderInvoiceList(headerType)
    },
    //获取发票抬头列表
    getOrderOrderInvoiceList(headerType){
      newOrderOrderInvoiceList(headerType).then(res=>{
        if(res.code == 200){
          this.dataList = res.data
        }
      })
    },
    headerTypeChange(e){
      this.dataForm = {
        "bankAccount": "",
        "bankDeposit": "",
        "companyAddress": "",
        "companyTelePhone": "",
        "contactTelePhone": "",
        "headerType": this.dataForm.headerType,
        "invoiceContent": "",
        "invoiceHeader": "",
        "invoiceId": "",
        "invoiceType": this.dataForm.invoiceType,
        "orderNo": this.$route.params.id,
        "receiveAddress": "",
        "receiveEmail": "",
        "unitTaxNo": ""
      }
    },
    //选择发票抬头
    handleAddEdit(row){
      this.dataForm = {
        "bankAccount": row.bankAccount,
        "bankDeposit": row.bankDeposit,
        "companyAddress": row.companyAddress,
        "companyTelePhone": row.companyTelePhone,
        "contactTelePhone": row.contactTelePhone,
        "headerType": row.headerType,
        "invoiceContent": row.invoiceContent,
        "invoiceHeader": row.invoiceHeader,
        "invoiceId": row.id,
        "invoiceType": row.invoiceType,
        "orderNo": this.$route.params.id,
        "receiveAddress": row.receiveAddress,
        "receiveEmail": row.receiveEmail,
        "unitTaxNo": row.unitTaxNo,
      }
      this.invoiceHeaderVisible = false
    },
    //删除抬头
    handleDel(id){
      this.$confirm('您确定要删除该数据吗？', '提示', {
        type: 'warning'
      }).then(() => {
        newOrderOrderInvoiceDel(id).then(res => {
          this.$message({
            type: 'success',
            message: res.msg,
            duration: 1500,
            onClose: () => {
              this.getOrderOrderInvoiceList(this.dataForm.headerType)
            }
          })
        }).catch(() => { })
      })
    },
    dataFormSubmit(){
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          newOrderOrderInvoiceApply(this.dataForm).then(res=>{
            newOrderOrderInvoiceAdd(this.dataForm).then(dates=>{

            })
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.btnLoading = false
                this.dialogVisible = false
                this.$emit('refreshDataList')
                this.$router.go(-1)
              }
            })
          }).catch(err=>{
            this.btnLoading = false
          })
        }
      })
    },
    resetForm(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.el-form-item{
  margin-bottom: 15px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding: 25px;
}
.JNPF-table-delBtn{
  color: red;
}
.page-bgf6{
  background: #fff;
  position: absolute;
  left: 200px;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.wrapper-content{
  margin-left: 0;
}
.demo-ruleForm {
  padding: 0 16px;

  ::v-deep .el-input,
  .el-select {
    width: 600px;
  }

  ::v-deep .el-textarea {
    width: 600px;
  }

  ::v-deep .el-textarea__inner {
    font-family: Microsoft YaHei;
  }

  ::v-deep .el-form-item__label {
    padding-right: 30px;
  }

  .noValid {
    ::v-deep .el-form-item__label {
      padding-left: 10.38px;
    }
  }
}

.partners-title {
  margin-top: 20px;
  padding: 0 24px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  height: 46px;

  h2 {
    border-bottom: solid 1px #e5e5e5;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
  }
}

.partners-content {
  padding: 24px 24px 54px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  min-height: 700px;
  margin-bottom: 20px;
}

.partners-info,
.partners-detail {
  border-top: 1px solid #ececec;
  font-size: 14px;
  color: #555;
  padding: 16px 22px;

  ul:first-child {
    padding-top: 0;
  }

  &.non-border {
    border-top: none;
  }

  &.non-paddingTop {
    padding-top: 0;
    margin-top: -16px;
  }

  .title {
    font-size: 18px;
    color: #333333;
    padding-bottom: 19px;
  }

  .item {
    padding-bottom: 16px;
    display: flex;

    .name {
      flex-shrink: 0;
      //width: 110px;
    }

    .value {
      flex: 1;
    }
  }
}

.partners-info {
  padding: 16px 16px;
}

.partners-detail {
  border: 1px solid #ececec;
}

.partners-btn,
.detail-btn {
  border-top: 1px solid #ececec;
  font-size: 14px;
  color: #555;
  padding: 16px;

  .checkbox-bar {
    .checkbox-content {
      color: #666666;
    }
  }

  .btn-bar {
    padding-top: 20px;
  }
}

.detail-btn {
  text-align: center;
}

</style>